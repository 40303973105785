.week-calendar {
  width: 300px;
}

.week-calendar .rc-calendar-tbody > tr:hover .rc-calendar-date {
  background: #ebfaff;
}

.input-datePicker {
  border: none;
  text-align: center;
  padding-top: 4px;
  width: 200px;
  cursor: pointer;
}
