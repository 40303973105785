html,
body,
#root {
  margin: 0;
  height: auto;
}

.Select-menu-outer {
  max-height: 20vh;
}

.Select-menu {
  max-height: 19.8vh;
}

#user-activities__select-activities .Select-menu {
  position: absolute;
  background: white;
  border: 1px solid lightgray;
  border-top: unset;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: calc(((100vw - 158px - 40px) * 0.55) * 0.5 - 1px);
  margin-left: -1px;
}

#user-activities__select-activities .Select {
  z-index: 0;
  position: static;
}

#user-activities__select-activities .Select-menu-outer {
  position: unset;
  left: unset;
  top: unset;
  width: calc(100% - 1px);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
